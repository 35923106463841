<template>
  <div>
    <!-- Header Slide Section -->
    <div
      class="header-slide mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="caption">
        <div class="box">
          <div class="container">
            <div class="inside-box py-4">
              <h1>Rooms at Amchara Gozo</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Featured In Tabs -->

    <featured-in></featured-in>
    <!-- Rooms Section -->
    <div class="container mt-5">
      <div class="row py-3 text-center">
        <div class="col-md-10 mx-auto">
          <p>
            At Amchara Health Retreat, we strive to offer our customers the best
            experience while staying with us. So all of our Gozo apartments have
            been styled sympathetically to the local Maltese styles and
            traditions, enabling you to relax and recharge fully. Below is some
            information that explains what can be found in each of our
            apartments. If you’d like to enquire about availability,
            <router-link to="/enquiries">click here.</router-link>
          </p>
        </div>
      </div>
    </div>

    <!-- Standard Apartment Section -->
    <div class="container">
      <div class="row py-5 text-center">
        <div class="col-md-10 mx-auto">
          <h2 style="color: #83c550" class="mb-5">Standard Apartments</h2>
          <div class="row">
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/standard_apartment_1.jpg"
                alt="Amcahra Health Retreat Standard Apartments"
                width="100%"
              />
            </div>
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/standard_apartment_2.jpg"
                alt="Amcahra Health Retreat Standard Apartments"
                width="100%"
              />
            </div>
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/standard_apartment_3.jpg"
                alt="Amcahra Health Retreat Standard Apartments"
                width="100%"
              />
            </div>
          </div>
          <p class="pt-4">
            Our standard apartments are decorated to a high standard and feature
            a solid oak double bed with a comfortable handmade mattress,
            spacious wardrobe with plenty of hangers and a large freestanding
            mirror.
          </p>
          <p style="color: #83c550">
            Flat Screen TV &middot; En-Suite Bathroom &middot; Hairdryer
            &middot; Small Kitchenette Area &middot; Fresh Towels Daily &middot;
            Comfortable Beds &middot; Free Wi-Fi &middot; Organic Soaps Lounge
            Area
          </p>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="container">
      <div class="row text-center">
        <div class="col-md-12">
          <router-link to="/malta-pricing" class="btn btn-green-gradient"
            >VIEW PRICES</router-link
          >
          <router-link to="/enquiries" class="btn btn-orange-gradient"
            >ENQUIRE NOW</router-link
          >
        </div>
      </div>
    </div>

    <!-- Executive Apartment Section -->
    <div class="container">
      <div class="row py-5 text-center">
        <div class="col-md-10 mx-auto">
          <h2 style="color: #83c550" class="mb-5">Executive Apartments</h2>
          <div class="row">
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/executive_apartment_1.jpg"
                alt="Amcahra Health Retreat Executive Apartments"
                width="100%"
              />
            </div>
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/executive_apartment_2.jpg"
                alt="Amcahra Health Retreat Executive Apartments"
                width="100%"
              />
            </div>
            <div class="col-md-4">
              <img
                src="@/assets/images/rooms/executive_apartment_3.jpg"
                alt="Amcahra Health Retreat Executive Apartments"
                width="100%"
              />
            </div>
          </div>
          <p class="pt-4">
            Executive apartments are brighter and offer more hours of sunlight
            throughout the day. They also provide the best views of the swimming
            pool and are conveniently situated for access to the facilities
            including the spa garden, juice room and the clinic.
          </p>
          <p style="color: #83c550">
            Large Flat Screen TV &middot; En-Suite Bathroom &middot; Hairdryer
            &middot; Small Kitchenette Area &middot; Fresh Towels Daily &middot;
            Comfortable Beds &middot; Free Wi-Fi &middot; Organic Soaps Lounge
            Area
          </p>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="container">
      <div class="row text-center">
        <div class="col-md-12">
          <router-link to="/malta-pricing" class="btn btn-green-gradient"
            >VIEW PRICES</router-link
          >
          <router-link to="/enquiries" class="btn btn-orange-gradient"
            >ENQUIRE NOW</router-link
          >
        </div>
      </div>
    </div>

    <!-- Testimonials -->
    <div style="margin-bottom: -3rem" class="mt-0">
      <testimonials></testimonials>
    </div>

    <!-- Newsletter -->
    <div>
      <newsletter-cta></newsletter-cta>
    </div>
  </div>
</template>

<script>
import Testimonials from "./page-partials/Testimonials";
import NewsletterCta from "./page-partials/NewsletterCta";
import FeaturedIn from "./page-partials/FeaturedIn";

export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      bgImages: [require("@/assets/images/rooms/malta_header.jpg")],
    };
  },
  components: {
    Testimonials,
    NewsletterCta,
    FeaturedIn,
  },
};
</script>

<style>
</style>